<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon title-with-icon_menu mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_back"></div>
          <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
          <div class="title-with-icon__delimeter">/</div>
          <div class="title-with-icon__title">Материально-техническое обеспечение и оснащенность образовательного процесса. Доступная среда
          </div>
        </div>

        <div class="mb-5 d-block d-md-none">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <div class="table-wrapper_indicator"></div>
            </div>
          </div>
        </div>

        <div class="table-wrapper table-wrapper_scrolled mb-7">
          <table class="table_bordered">
            <tbody>
            <tr itemprop="purposeCab">
              <td>Сведения об оборудованных учебных кабинетах</td>
              <td itemprop="addressCab">123610, г. Москва, Краснопресненская наб., д. 12</td>
              <td>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/77/41acfb75bc12d21c1344bb3751eba74c12c86329.pdf" class="content_underline content_red">Договор аренды помещений</a><br/>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/76/faa8196d5ede71970728343520a41fb88f97a011.jpeg" class="content_underline content_red">Заключение СЭ</a>
              </td>
              <td>
                Учебные кабинеты не приспособлены для инвалидов и лиц с ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemprop="purposePrac">
              <td>Сведения об объектах для проведения практических занятий</td>
              <td itemprop="addressPrac">123610, г. Москва, Краснопресненская наб., д. 12</td>
              <td>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/77/41acfb75bc12d21c1344bb3751eba74c12c86329.pdf" class="content_underline content_red">Договор аренды помещений</a><br/>
                <a href="https://exporteduru.servicecdn.ru/media/default/0001/76/faa8196d5ede71970728343520a41fb88f97a011.jpeg" class="content_underline content_red">Заключение СЭ</a>
              </td>
              <td>
                Учебные кабинеты не приспособлены для инвалидов и лиц с ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemprop="purposeLibr">
              <td>Сведения о библиотеке(ах)</td>
              <td>Имеется электронная библиотека</td>
              <td itemprop="objName">
                Электронная библиотека:<br/> <a href="/documents" class="content_underline content_red">https://exportedu.ru/documents</a>
              </td>
              <td>Электронные издания, размещенные в электронной библиотеке, имеют возможность увеличения шрифта</td>
            </tr>
            <tr>
              <td>Сведения об объектах спорта</td>
              <td itemprop="purposeSport" colspan="3">Объекты спорта отсутствуют</td>
            </tr>
            <tr itemprop="ovz">
              <td>Информация об обеспечении беспрепятственного доступа в здания образовательной организации</td>
              <td colspan="3">Нулевой вход, пандус, оборудованная санитарная комната, лифт с возможностью перевозки маломобильных групп</td>
            </tr>
            <tr itemprop="purposeFacil">
              <td>Сведения о средствах обучения и воспитания</td>
              <td colspan="3">
                Ноутбуки <strong class="content_700">39 шт.</strong> Проектор <strong class="content_700">1 шт.</strong> Доска магнитно-маркерная <strong class="content_700">2 шт.</strong><br/>
                Печатные и электронные образовательные и информационные ресурсы.<br/>
                Созданы технологические условия для реализации программ ДПО с применением электронного обучения и дистанционных образовательных технологий.
              </td>
            </tr>
            <tr itemprop="purposeFacil">
              <td>Информация о приспособленных средствах обучения и воспитания</td>
              <td colspan="3">
                Официальный сайт имеет версию для слабовидящих. Специальные технические и программные средства для обучения лиц с ОВЗ и инвалидов.
              </td>
            </tr>
            <tr itemprop="purposeFacil">
              <td>Сведения о доступе к информационным системам и информационно-телекоммуникационным сетям</td>
              <td colspan="3">
                Доступ к информационным системам и информационно-телекоммуникационным сетям предоставляется всем обучающимся.
                Обучающимся предоставлена возможность бесплатного доступа в сеть Интернет посредством Wi-Fi.
              </td>
            </tr>
            <tr itemprop="purposeFacil">
              <td>Информация о доступе к приспособленным информационным системам и информационно-телекоммуникационным сетям</td>
              <td colspan="3">
                Версия для слабовидящих людей официального сайта Школы экспорта, а также личного кабинета обучающегося.
              </td>
            </tr>
            <tr itemprop="comNet">
              <td>Наличие в образовательной организации электронной информационно-образовательной среды </td>
              <td colspan="3"><a href="https://exporteduru.servicecdn.ru/media/default/0001/76/3235fd746b85b15c4d82bfe7537f8493329dca1e.pdf" class="content_underline content_red">Положение об образовательном портале</a></td>
            </tr>
            <tr itemprop="meals">
              <td>Информация об электронных образовательных ресурсах, к которым обеспечивается доступ обучающихся</td>
              <td colspan="3">
                На сайте Школы экспорта реализован функционал для дистанционного обучения:<br/>
                <a href="https://exportedu.ru/education#online">https://exportedu.ru/education#online</a><br/><br/>
                <strong class="content_22">Федеральные информационно-образовательные ресурсы</strong><br/><br/>
                <a href="https://www.edu.ru/" target="_blank" class="content_underline content_red">
                  Официальный сайт Министерства образования и науки Российской Федерации
                  Федеральный портал «Российское образование»
                  Информационная система «Единое окно доступа к образовательным ресурсам»
                  Единая коллекция цифровых образовательных ресурсов
                  Федеральный центр информационно-образовательных ресурсов
                </a>
              </td>
            </tr>
            <tr itemprop="health">
              <td>Информация о приспособленных электронных образовательных ресурсах, к которым обеспечивается доступ обучающихся</td>
              <td colspan="3">
                Приспособленные электронные образовательные ресурсы, к которым обеспечивается доступ обучающихся отсутствуют
              </td>
            </tr>
            <tr itemprop="health">
              <td>Информация о наличии специальных технических средств обучения коллективного и индивидуального пользования</td>
              <td colspan="3">
                Специальные технические средства обучения коллективного и индивидуального пользования отсутствуют
              </td>
            </tr>
            <tr itemprop="health">
              <td>Наличие общежитий</td>
              <td colspan="3">
                Общежитие отсутствует, в том числе для использования инвалидами и лицами с ограниченными возможностями здоровья
              </td>
            </tr>
            <tr itemprop="health">
              <td>Наличие интернатов</td>
              <td colspan="3">
                Интернат отсутствует, в том числе для использования инвалидами и лицами с ограниченными возможностями здоровья
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="my-5 d-block">
          <a class="history-back" href="/sveden">Вернуться</a>
        </div>
        <div class="section__subsection_blue section__company-nav">
          <div class="section__subsection-wrapper">
            <company-navigation />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoMaterial",
  components: {CompanyNavigation, PageFooter},
  mounted() {
    this.$parent.init()
  }
}
</script>

<style lang="scss">
  .section__company {
    h4 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-top: 7rem!important;
      &.no-margin {
        margin: -40px 0 20px!important;
      }
    }
    .content_18 {
      a {
        color: #ed1b2f;
        text-decoration: underline;
      }
    }
  }
</style>